import React, { useEffect , useState } from 'react'
import styled from "styled-components"

import MainLayout from '../../../components/Layouts/MainLayout'
import Herocomp from '../../../components/Publicaciones/herocomp'
import TopBar from '../../../components/Publicaciones/TopBar'
import useContentful from '../../../utils/useContentful'
import { Link } from "gatsby"
import "./articulos.css"
const Container = styled.div`
  &.animate {
    animation: fade-up 1s;
  }
@keyframes fade-up {
  
  0%{
    opacity: 0;
    transform: translateY(200px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }

  @keyframes fade-down {
  
  0%{
    opacity: 0;
    transform: translateY(250px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }
`

const Cardcomponent = ({image, title, author,text, linkpoint}) => {

  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if(window.screen.width > 600){
      if (window.scrollY > 250) {
        setShow(true);
      } else {
        setShow(false);
      }}
      else{
        setShow(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return(
    <Container className={show ? "animate" : ""}>
    <div className="card">
      <a href={linkpoint} target="_blank" ><img className="image" src={image}/></a>
      <a href={linkpoint} target="_blank"><h1 className="title">{title}</h1></a>
      <h2 className="author">{author}</h2>
      <a href={linkpoint} target="_blank" style={{color: "black"}}><p className="text">{text}</p></a>
      <a href={linkpoint} target="_blank"><svg className="arrow" width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.7912 1.37089C3.79868 0.411064 2.19213 0.413795 1.20288 1.37699C0.213622 2.34018 0.216272 3.89909 1.2088 4.85892L4.7912 1.37089ZM13.9773 17.2068L15.7745 18.9447L19.3569 15.4567L17.5598 13.7188L13.9773 17.2068ZM1.2088 4.85892L13.9773 17.2068L17.5598 13.7188L4.7912 1.37089L1.2088 4.85892Z" fill="#A2B4BE"/>
<path d="M1.34914 26.2563C0.356612 27.2161 0.35409 28.7749 1.3435 29.7379C2.33291 30.7009 3.93959 30.7035 4.93211 29.7437L1.34914 26.2563ZM17.7013 17.3952L19.4984 15.6573L15.9155 12.1698L14.1183 13.9078L17.7013 17.3952ZM4.93211 29.7437L17.7013 17.3952L14.1183 13.9078L1.34914 26.2563L4.93211 29.7437Z" fill="#A2B4BE"/>
<path d="M14.8773 1.25724C13.8847 0.297497 12.278 0.300228 11.2887 1.26334C10.2993 2.22644 10.302 3.78522 11.2946 4.74496L14.8773 1.25724ZM24.0641 17.0917L25.8614 18.8294L29.4441 15.3417L27.6468 13.6039L24.0641 17.0917ZM11.2946 4.74496L24.0641 17.0917L27.6468 13.6039L14.8773 1.25724L11.2946 4.74496Z" fill="#A2B4BE"/>
<path d="M11.4349 26.1426C10.4423 27.1024 10.4398 28.661 11.4293 29.624C12.4188 30.5869 14.0256 30.5895 15.0182 29.6298L11.4349 26.1426ZM27.7884 17.2824L29.5856 15.5447L26.0024 12.0575L24.2051 13.7953L27.7884 17.2824ZM15.0182 29.6298L27.7884 17.2824L24.2051 13.7953L11.4349 26.1426L15.0182 29.6298Z" fill="#A2B4BE"/>
</svg></a>
    </div>
    </Container>
  )
}

const Cardcomponent2 = ({image, title, author,text}) => {

  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if(window.screen.width > 600){
      if (window.scrollY > 250) {
        setShow(true);
      } else {
        setShow(false);
      }}
      else{
        setShow(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return(
    <Link style={{color: "black"}} to={`/publicaciones/articulos/${title}`}>
      <Container className={show ? "animate" : ""}>
    <div className="card">
    <img className="image" src={image}/>
      <h1 className="title">{title}</h1>
      <h2 className="author">{author}</h2>
     <p className="text">{text}</p>


     <svg className="arrow" width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.7912 1.37089C3.79868 0.411064 2.19213 0.413795 1.20288 1.37699C0.213622 2.34018 0.216272 3.89909 1.2088 4.85892L4.7912 1.37089ZM13.9773 17.2068L15.7745 18.9447L19.3569 15.4567L17.5598 13.7188L13.9773 17.2068ZM1.2088 4.85892L13.9773 17.2068L17.5598 13.7188L4.7912 1.37089L1.2088 4.85892Z" fill="#A2B4BE"/>
<path d="M1.34914 26.2563C0.356612 27.2161 0.35409 28.7749 1.3435 29.7379C2.33291 30.7009 3.93959 30.7035 4.93211 29.7437L1.34914 26.2563ZM17.7013 17.3952L19.4984 15.6573L15.9155 12.1698L14.1183 13.9078L17.7013 17.3952ZM4.93211 29.7437L17.7013 17.3952L14.1183 13.9078L1.34914 26.2563L4.93211 29.7437Z" fill="#A2B4BE"/>
<path d="M14.8773 1.25724C13.8847 0.297497 12.278 0.300228 11.2887 1.26334C10.2993 2.22644 10.302 3.78522 11.2946 4.74496L14.8773 1.25724ZM24.0641 17.0917L25.8614 18.8294L29.4441 15.3417L27.6468 13.6039L24.0641 17.0917ZM11.2946 4.74496L24.0641 17.0917L27.6468 13.6039L14.8773 1.25724L11.2946 4.74496Z" fill="#A2B4BE"/>
<path d="M11.4349 26.1426C10.4423 27.1024 10.4398 28.661 11.4293 29.624C12.4188 30.5869 14.0256 30.5895 15.0182 29.6298L11.4349 26.1426ZM27.7884 17.2824L29.5856 15.5447L26.0024 12.0575L24.2051 13.7953L27.7884 17.2824ZM15.0182 29.6298L27.7884 17.2824L24.2051 13.7953L11.4349 26.1426L15.0182 29.6298Z" fill="#A2B4BE"/>
</svg>


    </div>
    </Container>
    </Link>
  )
}




const ArticuloEntryId = "1cz49tBW52Ik47wuHuBA4i";

const Articulos =(props) => {
const [data , setData] = useState()
  const { data: articulo, fetched } = useContentful({ id: ArticuloEntryId});



useEffect(()=>{

  const contentdata = [];

  const filterlink = articulo?.fields.articulos.filter(item => item.fields.onlyLinkArticle)
  const lala = filterlink?.map((item,index) => {

  return(
    {
      image: item.fields.cardImage.fields.file.url,
      title: item.fields.cardTitle,
      author: item.fields.author,
      text:item.fields.cardDescription ,
      linkpoint: item.fields.link ,

    }
  )

    
  })
 
  setData(lala);
 
}

, [articulo])

  return(
    <>
   { fetched && data && articulo?.fields.mostrarSeccion? <MainLayout active={5}>
      <Herocomp imageinput={articulo.fields.hero.fields.file.url}></Herocomp>
      <TopBar item={1}></TopBar>

    <div className="art2Container">
    <div className="art3Container">
      {articulo?.fields.articulos.map((item) => {
if (item.fields.onlyLinkArticle){
  let content =  
  {image: item.fields.cardImage.fields.file.url,
  title: item.fields.cardTitle,
  author: item.fields.author,
  text:item.fields.cardDescription ,
  linkpoint: item.fields.link ,
  }

  return (
    <Cardcomponent image={content.image} title={content.title} author={content.author} text={content.text} linkpoint={content.linkpoint}></Cardcomponent>
  )

} else{
  let content2 =  
  {image: item.fields.cardImage.fields.file.url,
  title: item.fields.cardTitle,
  author: item.fields.author,
  text:item.fields.cardDescription ,
  linkpoint: item.fields.link ,
  }
  return (
    <Cardcomponent2 image={content2.image} title={content2.title} author={content2.author} text={content2.text} ></Cardcomponent2>
  )
}
     
      }
      )}
    </div>
    </div>

    </MainLayout>:
     <div
     style={{
       width: "100vw",
       height: "100vh",
       display: "flex",
       justifyContent: "center",
       marginTop: "0px",
       padding: "15%",
     }}
   >
     {" "}
     <svg
       version="1.1"
       id="L9"
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       viewBox="0 0 100 100"
       enableBlackground="new 0 0 0 0"
       xmlSpace="preserve"
     >
       <path
         fill="#008991"
         d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
       >
         <animateTransform
           attributeName="transform"
           attributeType="XML"
           type="rotate"
           dur="1s"
           from="0 50 50"
           to="360 50 50"
           repeatCount="indefinite"
         />
       </path>
     </svg>
   </div>
}
   </>
  );
}

export default Articulos;